import { AbstractControl, FormGroup, ValidatorFn } from "@angular/forms";

export function notOnlyNumbersValidator(allowEmpty = true): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (allowEmpty && value === "") {
      return null;
    }

    const isOnlyNumbers = /^\d+$/.test(value);
    return isOnlyNumbers ? { onlyNumbersError: { value: control.value } } : null;
  };
}

export function atLeastOneRequiredValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const group = control as FormGroup;
    let fields = group.controls;
    if (Object.keys(fields).every((fieldName) => !fields[fieldName].value)) {
      return {
        atLeastOneRequired: true
      };
    }
    return null;
  };
}
