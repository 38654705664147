<div class="d-flex position-relative" ngbDropdown container="body">
  <button
    ngbDropdownToggle
    role="button"
    type="button"
    class="btn btn-primary btn-card-action float-end dropdown-toggle"
    [id]="'expandable-button-' + title"
    [disabled]="disabled"
  >
  <div class="d-flex text-nowrap">
    <span>{{ title }}</span>
    <i class="ms-2 arrow-down-icon"></i>
  </div>
  </button>

  <div ngbDropdownMenu class="dropdown-menu">
    <span
      *ngFor="let item of options"
      ngbDropdownItem
      class="dropdown-item"
      (click)="onSelect(item.value)">
      <span>{{ item.label }}</span>
    </span>
  </div>
</div>
