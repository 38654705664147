import { of } from "rxjs";

export const NULL_OBSERVABLE = of(null);

export const nonFalsyPayload = <T extends {}>(payload: T) =>
  Object.entries(payload).reduce((acc: { [key: string]: any }, [k, v]) => {
    if (!!v) {
      acc[k] = v;
    }
    return acc;
  }, <T>{});
