import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from "@angular/core";

@Directive({
  selector: "[libClickPrevent]",
  standalone: true
})
export class ClickPreventDirective {
  @Input("libClickPrevent") isDisabled: boolean = false;

  @Output() allowedClick = new EventEmitter<void>();

  @HostBinding("class.disabled") get disabled() {
    return this.isDisabled;
  }

  @HostBinding("class.enabled") get enabled() {
    return !this.isDisabled;
  }

  @HostListener("click") onClick(): void {
    if (!this.isDisabled) {
      this.allowedClick.emit();
    }
  }
}
