import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModalModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { QrCodeReaderButtonComponent, QrCodeReaderModalComponent } from ".";
import { provideUikitTranslocoScope } from "../utils";

@NgModule({
  declarations: [QrCodeReaderButtonComponent, QrCodeReaderModalComponent],
  imports: [CommonModule, TranslocoModule, NgbTooltipModule, NgbModalModule],
  providers: [provideUikitTranslocoScope()],
  exports: [QrCodeReaderButtonComponent, QrCodeReaderModalComponent]
})
export class QrCodeReaderModule {}
