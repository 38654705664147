import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { DropdownPosition } from "@ng-select/ng-select";

@Component({
  selector: "lib-dropdown",
  templateUrl: "./dropdown.component.html",
  styleUrls: ["./dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class DropdownComponent {
  @Input() control: FormControl | null = null;
  @Input() isRequired = false;
  @Input() clearable = false;
  @Input() closeOnSelect = true;
  @Input() searchable = false;
  @Input() readonly = false;
  @Input() multiple = false;
  @Input() dropdownPosition: DropdownPosition = "auto";
  @Input() label = "";
  @Input() bindLabel = "";
  @Input() bindValue = "";
  @Input() placeholder = "";
  @Input() items = [];

  @Input() set appendOptionsToBody(value: boolean) {
    this.appendOptionsTo = value ? "body" : null;
  }

  @Output() change = new EventEmitter();

  private appendOptionsTo: "body" | null = null;

  get appendTo(): string {
    return <string>this.appendOptionsTo;
  }
}
