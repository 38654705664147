import { Component, EventEmitter, Input, ViewChild } from "@angular/core";
import { MatMenuTrigger, MenuPositionX, MenuPositionY } from "@angular/material/menu";
import { InputComponentBase } from "../../base/input-component-base";
import { IFilterSelectItem } from "../i-filter-select-item";

@Component({ template: "" })
export abstract class FilterSelectComponentBase<TValue, TControlValue = TValue> extends InputComponentBase<TControlValue> {
  @Input() itemsMaxHeightPx? = 400;
  @Input() itemsWidthPx = 340;
  @Input() xPosition: MenuPositionX = "after";
  @Input() yPosition: MenuPositionY = "below";
  @Input("items") set __items(val: IFilterSelectItem<TValue>[]) {
    this.items = val ?? [];
    this.filter();
    this.updateSelectedFromControl();
  }
  @Input() canClear = false;

  @ViewChild("menuTrigger") public set __menuTrigger(val: MatMenuTrigger) {
    this.menuTrigger = val;
    if (val) this.onMenuTriggerInit.emit(val);
  }

  protected menuTrigger!: MatMenuTrigger;
  onMenuTriggerInit = new EventEmitter<MatMenuTrigger>();
  filterText = "";
  items: (IFilterSelectItem<TValue> & { isSelected?: boolean })[] = [];
  filteredItems: (IFilterSelectItem<TValue> & { isSelected?: boolean })[] = [];

  protected abstract updateSelectedFromControl(): void;

  filter(): void {
    this.filteredItems = this.items.filter(
      (x) =>
        x.title?.toLowerCase().includes(this.filterText.toLowerCase()) ||
        x.description?.toLowerCase().includes(this.filterText.toLowerCase())
    );
  }

  filterInputKeydown($event: KeyboardEvent): void {
    if ($event.key === "Escape") {
      this.filterText = "";
      this.filter();
    }
    $event.stopPropagation(); // Fix: MatMenu loses focus with numeric input.
  }

  closeMenu(): void {
    this.menuTrigger.closeMenu();
  }

  override writeValue(value: TControlValue): void {
    this.updateSelectedFromControl();
  }
}
