import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { CLEARLINE_API_CONFIG_TOKEN, ClearlineApiConfig, ClearlineApiModule } from "clearline-api";
import { AppConfigService, CoreKitConfig, CoreKitModule } from "core-kit";
import { ToastComponent } from "ui-kit";
import { environment } from "../environments/environment";
import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routes";
import { TranslocoRootModule } from "./transloco/transloco-root.module";

const coreKitConfig: CoreKitConfig = {
  baseName: environment.baseName,
  apiName: environment.apiName,
  isProduction: environment.production,
  selfServicePortalUrl: environment.selfServicePortalUrl
};

export function appConfigServiceFactory(appService: AppConfigService): Function {
  return () => appService.getAppData();
}

export function clearlineApiConfigFactory(appConfigService: AppConfigService): ClearlineApiConfig {
  return {
    apiUrl: appConfigService.apiUrl
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    RouterModule.forRoot(appRoutes),
    CoreKitModule.forRoot(coreKitConfig),
    ClearlineApiModule,
    BrowserModule,
    TranslocoRootModule,
    ToastComponent
  ],
  providers: [
    AppConfigService,
    { provide: APP_INITIALIZER, useFactory: appConfigServiceFactory, deps: [AppConfigService], multi: true },
    {
      provide: CLEARLINE_API_CONFIG_TOKEN,
      useFactory: clearlineApiConfigFactory,
      deps: [AppConfigService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
