import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Toast, ToastOptions } from "./toast";

@Injectable({
  providedIn: "root"
})
export class ToastService {
  toastList$ = new BehaviorSubject<Toast[]>([]);
  delay = 5000;

  constructor() {}

  info(content: any) {
    this.show(content, { delay: this.delay, className: "bg-info text-light" });
  }

  success(content: any) {
    this.show(content, { delay: this.delay, className: "bg-success text-light" });
  }

  danger(content: any) {
    this.show(content, { delay: this.delay, className: "bg-danger text-light" });
  }

  remove(toast: Toast) {
    const toasts = this.toastList$.value.filter((t) => t !== toast);
    this.toastList$.next(toasts);
  }

  private show(content: TemplateRef<any> | null, options: ToastOptions = { delay: this.delay, className: "" }) {
    if (content) {
      const toasts = [...this.toastList$.value, { content, ...options }];

      this.toastList$.next(toasts);
    }
  }
}
