import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";

export interface ConfirmDialogOptions {
  title: string;
  description?: string;
  cancelBtnLabel?: string;
  confirmBtnLabel: string;
}

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent implements OnInit {
  title = "";
  description = "";
  cancelBtnLabel = "";
  confirmBtnLabel = "";

  constructor(@Inject(MAT_DIALOG_DATA) private options: ConfirmDialogOptions, private dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

  ngOnInit(): void {
    const { title, description, cancelBtnLabel, confirmBtnLabel } = this.options;

    this.title = title;
    this.description = description || "";
    this.cancelBtnLabel = cancelBtnLabel || "";
    this.confirmBtnLabel = confirmBtnLabel;
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
