<div class="form-group position-relative" *ngIf="control">
  <label class="col-md-3 col-form-label">{{ label }}</label>

  <ng-select
    class="disable-arrow"
    [items]="[]"
    [formControl]="control"
    [class.is-invalid]="control.touched && control.errors"
    [addTag]="true"
    [multiple]="true"
    [selectOnTab]="true"
    [isOpen]="false"
    (add)="onAdd($event)"
    (blur)="onBlur($event)"
    [placeholder]="placeholder"
    (remove)="remove.emit(null)"
  >
    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <span class="ng-value-label">{{ item }}</span>

      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
        <img alt="" src="assets/images/close.svg" width="16" height="16" />
      </span>
    </ng-template>
  </ng-select>

  <ng-container *ngIf="control.touched && control.errors">
    <ng-container *ngIf="control.errors.isValid && isValidErrorMessage">
      <div *transloco="let t; read: 'senders'" class="invalid-feedback">{{ isValidErrorMessage }}</div>
    </ng-container>
    <ng-container *ngIf="control.errors.unique && uniqueErrorMessage">
      <div *transloco="let t; read: 'senders'" class="invalid-feedback">{{ uniqueErrorMessage }}</div>
    </ng-container>
  </ng-container>
</div>
