import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IframeInputPostMessageDirective, IframeResizeDirective } from "./directives";

@NgModule({
  imports: [CommonModule],
  exports: [IframeInputPostMessageDirective, IframeResizeDirective],
  declarations: [IframeInputPostMessageDirective, IframeResizeDirective]
})
export class ClearlineCommonModule {}
