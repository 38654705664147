import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { TranslocoModule } from "@ngneat/transloco";
import { FormComponentsModule } from "../../form-components";

export interface OptionSelectDialogConfig {
  title: string;
  optionsLabel: string;
  bindLabel: string;
  bindValue: string;
  options: never[];
}

@Component({
  selector: "app-option-select-dialog",
  templateUrl: "./option-select-dialog.component.html",
  styleUrls: ["./option-select-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslocoModule, MatDialogModule, ReactiveFormsModule, FormComponentsModule]
})
export class OptionSelectDialogComponent implements OnInit {
  title = "";
  optionsLabel = "";
  bindLabel = "label";
  bindValue = "value";
  options = [];

  form = new FormGroup({
    option: new FormControl(null, [Validators.required])
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: OptionSelectDialogConfig,
    private dialogRef: MatDialogRef<OptionSelectDialogComponent>
  ) {}

  ngOnInit(): void {
    const { options, title, optionsLabel, bindLabel, bindValue } = this.data;
    this.title = title;
    this.optionsLabel = optionsLabel;
    this.bindLabel = bindLabel || this.bindLabel;
    this.bindValue = bindValue || this.bindValue;
    this.options = options;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    const selectedOption = this.options.find((option) => option[this.bindValue] === this.form.value.option);

    this.dialogRef.close(selectedOption);
  }
}
