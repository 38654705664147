import packageJson from "../../../../../package.json";
import { BuildDetails } from "./build-details";

export class VersionDetails {
  static get version(): string {
    return packageJson.version;
  }
  static get buildDate(): Date {
    return new Date(BuildDetails.isoDate);
  }

  static get buildStamp(): string {
    const date = this.buildDate;
    const year = date.getUTCFullYear().toString().slice(-2);
    const res = `${year}${date.getUTCMonth()}${date.getUTCDate()}${date.getUTCHours()}${date.getUTCMinutes()}`;
    return res;
  }
  static get versionFull(): string {
    const res = `${this.version}b${this.buildStamp}`;
    return res;
  }
}
