import { Component, Injector, Input, OnInit, inject } from "@angular/core";
import { ControlValueAccessor, FormControl, NgControl, NgModel, ValidationErrors, Validator } from "@angular/forms";

@Component({
  template: "",
  providers: [
    //{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => _Parent_Component_), multi: true },
    //{ provide: NG_VALIDATORS, useExisting: forwardRef(() => _Parent_Component_), multi: true },
  ]
})
export abstract class InputComponentBase<TValue = unknown> implements ControlValueAccessor, Validator, OnInit {
  private injector = inject(Injector);

  @Input() label?: string;
  @Input() placeholder = "";
  protected ngControl: NgControl | null = null;

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl, null);
  }

  protected get formControl(): FormControl<TValue> {
    return <FormControl<TValue>>this.ngControl?.control;
  }

  /** Execute to notify model value updated on 'ngModel' binding. */
  onNgModelChange(value: TValue) {
    if (this.ngControl instanceof NgModel && this.onChange) this.onChange(value);
  }

  //~ 'ControlValueAccessor' implementation:
  writeValue(value: TValue) {}
  setDisabledState(disabled: boolean) {}
  protected onChange?: (value: TValue) => void = () => {};
  registerOnChange(onChange: (value: TValue) => void) {
    this.onChange = onChange;
  }
  protected onTouched?: () => void;
  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  //~ 'Validator' implementation:
  validate(control: FormControl<TValue>): ValidationErrors | null {
    return null;
  }
  onValidatorChange?: () => void;
  registerOnValidatorChange?(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }
}
