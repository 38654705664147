import { AfterViewInit, Directive, Input, OnDestroy, Renderer2 } from "@angular/core";

@Directive({
  selector: "[libBodyCssClassAppend]"
})
export class BodyCssClassAppendDirective implements AfterViewInit, OnDestroy {
  @Input() libBodyCssClassAppend = "";
  @Input() targetCssClass = "";

  private observer: MutationObserver | null = null;
  private targetSelector = ".ng-dropdown-panel";

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    if (this.libBodyCssClassAppend === "body") {
      this.initMutationObserver();
    }
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  private initMutationObserver(): void {
    this.observer = new MutationObserver((v) => {
      if (this.targetCssClass) {
        this.updateCssClass();
      }
    });

    this.observer.observe(document.body, {
      childList: true,
      subtree: false
    });
  }

  private updateCssClass(): void {
    const targetElement = document.querySelector("body")?.querySelector(this.targetSelector);

    if (targetElement && targetElement.parentElement === document.body) {
      this.renderer.addClass(targetElement, this.targetCssClass);
    }
  }
}
