import { DatePipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, forwardRef, Input, ViewChild } from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { NgbDatepicker, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { dateToNgbDateStruct, ngbDateStructToDate } from "../../utils";

@Component({
  selector: "lib-datepicker",
  templateUrl: "./datepicker.component.html",
  styleUrls: ["./datepicker.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor {
  @Input() dateFormat: string = "dd/MM/yyyy";
  @Input() label = "";
  @Input() placeholder = "";
  @Input() isRequired = false;
  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };
  @Input() maxDate: NgbDateStruct = { year: 2050, month: 1, day: 1 };

  @ViewChild(NgbDatepicker) datepicker: NgbDatepicker | null = null;

  isVisible = false;
  selectedDate: NgbDateStruct | null = null;
  control = new FormControl<string | null>(null);
  utcValue: string | null = null;

  constructor(private datePipe: DatePipe) {}

  private onChange: any = () => {};
  private onTouched: any = () => {};

  writeValue(date: string | null): void {
    if (date) {
      const ngbDate = dateToNgbDateStruct(new Date(date));

      this.selectedDate = ngbDate;
      this.utcValue = date;
      this.updateControlValue(this.selectedDate, false);
    } else {
      this.utcValue = null;
      this.selectedDate = null;
      this.control.setValue(null);
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  isSelected(date: NgbDateStruct): boolean {
    const ngbDate = this.getNgbDateFromControlValue();

    return JSON.stringify(ngbDate) === JSON.stringify(date);
  }

  onClick(): void {
    this.toggleDatepicker();
  }

  onDateSelect(date: NgbDateStruct): void {
    this.updateControlValue(date);
    this.toggleDatepicker();
  }

  private toggleDatepicker(): void {
    this.isVisible = !this.isVisible;

    if (this.datepicker) {
      const ngbDate = this.getNgbDateFromControlValue();

      this.datepicker.navigateTo(ngbDate!);
    }
  }

  private getNgbDateFromControlValue(): NgbDateStruct {
    const now = Date.now();
    const date = new Date(this.control.value || now);

    return dateToNgbDateStruct(date);
  }

  private formatSelectedDate(date: string | Date): string | null {
    return this.datePipe.transform(date, this.dateFormat, "UTC");
  }

  private updateControlValue(ngbDate: NgbDateStruct, emitModelChange = true): void {
    const date = ngbDateStructToDate(ngbDate);
    const formatted = this.formatSelectedDate(date);

    if (emitModelChange) {
      this.utcValue = date.toISOString();
      this.onModelChange();
    }

    this.control.setValue(formatted);
  }

  private onModelChange(): void {
    this.onChange(this.utcValue);
    this.onTouched();
  }
}
