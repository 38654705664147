import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "../../core";
import { PagesWidgetQrCodeDataDto, PagesWidgetCompletePostDto } from "../../dto";

@Injectable({
  providedIn: "root"
})
export class PagesWidgetService {
  private serviceUrl = "pages/widget";

  constructor(private api: ApiService) {}

  send(payload: PagesWidgetCompletePostDto): Observable<void> {
    return this.api.post(`${this.serviceUrl}/send`, payload);
  }

  getQrCode(widgetType: string, locationId: number): Observable<PagesWidgetQrCodeDataDto> {
    return this.api.get(`${this.serviceUrl}/${widgetType}/qr-code?locationId=${locationId}`);
  }
}
