import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { CoreKitConfig } from "./models";

export const CORE_KIT_CONFIG_TOKEN = new InjectionToken<string>("core-kit_config_token");
export const BASE_URL_TOKEN = new InjectionToken<string>("BASE_URL");

const providers = [{ provide: BASE_URL_TOKEN, useFactory: getBaseUrl, deps: [] }];

export function getBaseUrl() {
  return document.getElementsByTagName("base")[0].href;
}
@NgModule({
  imports: [CommonModule, HttpClientModule]
})
export class CoreKitModule {
  public static forRoot(config: CoreKitConfig): ModuleWithProviders<CoreKitModule> {
    return {
      ngModule: CoreKitModule,
      providers: [
        ...providers,
        {
          provide: CORE_KIT_CONFIG_TOKEN,
          useValue: config
        }
      ]
    };
  }
}
