import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "lib-tags-input",
  templateUrl: "./tags-input.component.html",
  styleUrls: ["./tags-input.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default
})
export class TagsInputComponent {
  @Input() label = "";
  @Input() placeholder = "";
  @Input() control: FormControl | null = null;
  @Input() isValidErrorMessage = "";
  @Input() uniqueErrorMessage = "";
  // todo: finish refactoring TagsInputComponent

  @Output() blur = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() remove = new EventEmitter();

  onBlur($element: Event) {
    if (this.control) {
      const selectElement = $element.target as HTMLSelectElement;
      const value = selectElement.value.trim();
      const cc = this.control.value as string[];

      if (value) {
        cc.push(value);
        this.control.setValue(cc);
      }

      this.remove.emit(null);

      selectElement.value = "";
    }
  }

  onAdd(value: string) {
    if (value && this.control) {
      let cc = this.control.value as string[];
      cc = cc.filter((c) => c !== value);

      cc.push(value.trim());
      this.control.setValue(cc);
      this.remove.emit(null);
    }
  }
}
