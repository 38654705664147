//fixme: probably this file should be a part of `core-kit`?
import { Provider } from "@angular/core";
import { HashMap, provideTranslocoScope, Translation } from "@ngneat/transloco";

type LanguageLoader = HashMap<() => Promise<Translation>>;

// todo langs array should be moved to a shared constant or environment file
const supportLanguages = ["en", "es"];

const translocoLoader: LanguageLoader = supportLanguages.reduce((acc: LanguageLoader, lang) => {
  acc[lang] = () => import(`../../i18n/${lang}.json`);
  return acc;
}, {});

export const i18nScope = "uikit";

export function provideUikitTranslocoScope(): Provider {
  return provideTranslocoScope({
    scope: i18nScope,
    loader: translocoLoader
  });
}
