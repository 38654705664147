<app-input
  *ngIf="control"
  [readonly]="true"
  [icon]="'mdi mdi-calendar-blank-outline cursor-pointer'"
  [label]="label"
  [control]="control"
  [isRequired]="isRequired"
  [placeholder]="placeholder"
  (iconClick)="onClick()"
></app-input>


<ngb-datepicker
  #datepicker="ngbDatepicker"
  autoClose="outside"
  [class.d-none]="!isVisible"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [dayTemplate]="customDayTemplate"
  (dateSelect)="onDateSelect($event)"
></ngb-datepicker>


<ng-template #customDayTemplate let-date="date">
  <span class="custom-day" [class.highlighted]="isSelected(date)">
    {{ date.day }}
  </span>
</ng-template>
