import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function isValidPhone(value: string) {
  const phoneRegex: RegExp = /^\+?[0-9]{10,11}$/; // todo check phone format
  const isValid = phoneRegex.test(value);
  return isValid;
}

export function phoneValidator(allowEmpty = false): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const value = control.value.toString() as string;

    if (!value && allowEmpty) {
      return null;
    }

    const isValid = isValidPhone(value);

    return isValid ? null : { isValid: { value: control.value }, type: "phone" };
  };
}
