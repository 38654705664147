export enum WidgetType { // CampaignWidgetTypes
  IssueCoupon = 1,
  ValidateCoupon = 2,
  EnrollToLoyalty = 3,
  IssuePointsOrStamps = 4,
  RequestReview = 5,
  SetReminder = 6,
  AddPromo = 7,
  DownloadMobileApp = 8,
  ConnectSocialNetwork = 9,
  OneTimePayment = 10,
  AnniversaryClub = 11,
  SendGiftCard = 12,
  SendSurvey = 13,
  AddToWaitlist = 14,
  AddToReferralProgram = 15,
  ScheduleAppointment = 16,
  ConfirmEmail = 17,
  RequiredAdminConfiguration = 18,
  AccountReadyToUse = 19,
  UserPendingConfiguration = 20,
  SendUserPassword = 21,
  ForgotPassword = 22,
  MonthlyLimitReached = 23,
  RequestDigitLocalNumber = 24,
  RequestDedicatedTollfree = 25,
  RequestExistingNumber = 26,
  NotifyAdminWidgetConfig = 27,
  NotifyManagerWidgetConfig = 28,
  NotifyAdminNewLocation = 29,
  CustomerFacingScreen = 30,
  NegativeFeedback = 31,
  SalesAgentCreated = 32,
  JobExecutionError = 33,
  SmartPage = 34,
  StartNewAccountRegistration = 36,
  SeeMenu = 37,
  KiosPrepaidSmsTransaction = 38,
  SendPinCode = 39,
  DeviceBlocked = 40,
  ResetPinCode = 41,
  MessageErrorReport = 42,
  SendOnboardingUrl = 43,
  SendActivationCode = 44,
  GenericTemplate = 45,
  CustomerDistribution = 46
}

export enum CampaignType {
  EmailConfirmation = 17,
  PendingAdminConfiguration = 18,
  ManagerAccountConfigured = 19,
  UserPendingConfiguration = 20,
  SendUserPassword = 21,
  ForgotPassword = 22,
  MonthlyLimitReached = 23,
  Request10DigitLocalNumber = 24,
  RequestDedicatedTollFreeNumber = 25,
  RequestExistingLandlineNumber = 26,
  NotifyAdminForWidgetConfig = 27,
  NotifyManagerForWidgetConfig = 28,
  NotifyAdminForLocationConfig = 29,
  SendNegativeFeedback = 31,
  SendSalesAgentCreated = 32,
  SendJobFailNotification = 33,
  NewAccountRegistration = 35,
  NewGenericAccountRegistration = 36
}

export enum WidgetCodes {
  EnrollToLoyalty = "enrollLoyalty",
  RequestReview = "requestReview",
  IssueCoupon = "issueCoupon",
  IssuePointsOrStamps = "issuePoints",
  ValidateCoupon = "validateCoupon",
  ScheduleAppointment = "scheduleAppointment",
  AddToWaitlist = "waitlist",
  SendSurvey = "sendSurvey",
  DownloadMobileApp = "downloadMobileApp",
  SendGiftCard = "sendGiftCard",
  SetReminder = "setReminder",
  ConnectSocialNetwork = "connectSocialMedia",
  AddPromo = "addPromo",
  OneTimePayment = "sendPaymentLink",
  AnniversaryClub = "anniversaryClub",
  AddToReferralProgram = "referralProgram",
  FAQ = "faq",
  SmartPage = "smartPage",
  SeeMenu = "seeMenu"
}

export enum LocationWidgetSettingsMode {
  Cmc = "Cmc",
  Cfs = "Cfs"
}
