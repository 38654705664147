<div class="option-select-modal">
  <div class="modal-header ps-4 pe-4">
    <h2 *ngIf="title" class="modal-title fw-bold">
      {{ title }}
    </h2>

    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onClose()"></button>
  </div>

  <div class="modal-body ps-5 pe-5 pt-0">
    <form [formGroup]="form">
      <lib-dropdown
        class="d-block mt-3"
        [bindLabel]="bindLabel"
        [bindValue]="bindValue"
        [control]="form.controls.option"
        [isRequired]="true"
        [items]="options"
        [label]="optionsLabel"
        [placeholder]="optionsLabel"
        [appendOptionsToBody]="true"
        [searchable]="true"
      ></lib-dropdown>
    </form>
  </div>

  <div class="modal-footer ps-5 pe-5">
    <div class="d-flex justify-content-between w-100">
      <button
        class="btn btn-outline-primary me-3"
        (click)="onClose()">
        {{ 'common.controls.close' | transloco }}
      </button>

      <button
        class="btn btn-primary ms-3"
        [disabled]="!form.valid"
        (click)="onConfirm()">
        {{ 'common.controls.create' | transloco }}
      </button>
    </div>
  </div>
</div>
