import { CommonModule } from "@angular/common";
import { AfterViewInit, Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { SimplebarAngularModule } from "simplebar-angular";
import { FilterSelectComponentBase } from "../base/filter-select-component-base";
import { IFilterSelectItem } from "../i-filter-select-item";

/** Filter-multiselect for lists.
 * @example: <lib-filter-select-multiple [ngModel]="" [items]="items" label="Filter..." [canClear]="true"> </lib-filter-select-multiple>
 * @example: <lib-filter-select-multiple [ngModel]="" [items]="items" label="Filter..." [canClear]="true" itemsMaxHeightPx="200" itemsWidthPx="400" xPosition="before" yPosition="above"> </lib-filter-select-multiple>
 */
@Component({
  selector: "lib-filter-select-multiple",
  standalone: true,
  imports: [CommonModule, FormsModule, MatButtonModule, MatMenuModule, NgbTooltipModule, SimplebarAngularModule, TranslocoModule],
  templateUrl: "./filter-select-multiple.component.html",
  styleUrls: ["./filter-select-multiple.component.scss", "../filter-select-common.scss"],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FilterSelectMultipleComponent), multi: true }]
})
export class FilterSelectMultipleComponent<TValue = number>
  extends FilterSelectComponentBase<TValue, TValue[]>
  implements OnInit, AfterViewInit
{
  @Input() disabled = false;
  @ViewChild("menuContent") menuContent!: ElementRef;
  @ViewChild("menuTriggerButton", { read: ElementRef }) menuTriggerButton!: ElementRef;

  selectedItems: IFilterSelectItem<TValue>[] = [];
  menuSelectedCount = 0;
  isAllSelected = false;
  readonly menuPaddingX = 8;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    this.updateSelectedFromControl();
    this.adjustMenuPosition();
  }

  menuOpened(): void {
    // TODO: Use Custom Angular CDK Overlay or ngbDropdown to adjust menu width and position.
    const menuContentWidth = this.menuContent.nativeElement.offsetWidth;
    let currentElement = this.menuContent.nativeElement;

    if (currentElement && !currentElement.style.width) {
      while (currentElement) {
        if (currentElement.classList.contains("cdk-overlay-pane")) {
          currentElement.style.width = `${menuContentWidth}px`;
          break;
        }
        currentElement = currentElement.parentElement;
      }
    }
  }

  menuClosed(): void {
    this.updateSelectedFromControl();
  }

  selectAllChanged(isSelectAll: boolean): void {
    this.items.forEach((item) => {
      item.isSelected = isSelectAll;
    });
    this.selectionChanged();
  }

  protected updateSelectedFromControl(): void {
    const value: TValue[] = this.formControl?.value ?? [];

    this.items.forEach((item) => {
      item.isSelected = value.some((x) => item.value === x);
    });
    this.selectionChanged();

    this.selectedItems = this.items.filter((x) => x.isSelected);
  }

  selectionChanged(): void {
    this.menuSelectedCount = this.items.filter((x) => x.isSelected).length;
    this.isAllSelected = this.menuSelectedCount === this.items.length;
  }

  get tooltipItems(): string[] {
    let items: string[] = this.selectedItems.map((x) => x.title).sort();
    const total = items.length;
    const maxItemsCount = 15;

    if (items.length > maxItemsCount) {
      items = items.slice(0, maxItemsCount - 1);
      const notVisibleCount = total - items.length;

      items.push(`... +${notVisibleCount} more`);
    }

    return items;
  }

  resetClick(): void {
    this.filterText = "";

    this.items.forEach((x) => (x.isSelected = false));
    this.selectionChanged();
    this.filter();
  }

  okClick(): void {
    const value: TValue[] = this.items.filter((x) => x.isSelected).map((x) => x.value);

    this.formControl.setValue(value);
    this.closeMenu();
  }

  private adjustMenuPosition(): void {
    const triggerRect = this.menuTriggerButton.nativeElement.getBoundingClientRect();
    const gap = 10;
    const menuContentWidth = this.itemsWidthPx + this.menuPaddingX * 2 + gap;
    const viewportWidth = window.innerWidth;

    if (triggerRect.left + menuContentWidth > viewportWidth) {
      this.xPosition = "before";
    } else {
      this.xPosition = "after";
    }
  }
}
