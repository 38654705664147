import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { NgbDatepickerModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslocoModule } from "@ngneat/transloco";
import { NgxMaskModule } from "ngx-mask";
import {
  DatepickerComponent,
  DropdownComponent,
  FormFieldErrorComponent,
  FormLabelComponent,
  HrSeparatorComponent,
  InputComponent,
  TextareaComponent
} from ".";
import { provideUikitTranslocoScope } from "../utils";
import { BodyCssClassAppendDirective } from "./directives";
import { TagsInputComponent } from "./tags-input/tags-input.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot({ validation: false }),
    TranslocoModule,
    NgSelectModule,
    NgbDatepickerModule,
    NgbTooltipModule
  ],
  providers: [provideUikitTranslocoScope()],
  declarations: [
    BodyCssClassAppendDirective,
    DatepickerComponent,
    DropdownComponent,
    FormFieldErrorComponent,
    FormLabelComponent,
    InputComponent,
    HrSeparatorComponent,
    TextareaComponent,
    TagsInputComponent
  ],
  exports: [
    DatepickerComponent,
    DropdownComponent,
    FormFieldErrorComponent,
    FormLabelComponent,
    InputComponent,
    HrSeparatorComponent,
    TextareaComponent,
    TagsInputComponent
  ]
})
export class FormComponentsModule {}
