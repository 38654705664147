import { CommonModule } from "@angular/common";
import { Component, forwardRef } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatMenuModule } from "@angular/material/menu";
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { SimplebarAngularModule } from "simplebar-angular";
import { IFilterSelectItem } from "../i-filter-select-item";
import { FilterSelectComponentBase } from "../base/filter-select-component-base";
import { FilterSelectModule } from "./filter-select.module";

/** Filter for lists.
 * @example: <lib-filter-select [ngModel]="" [items]="items" label="Filter..." [canClear]="true"> </lib-filter-select>
 * @example: <lib-filter-select [ngModel]="" [items]="items" label="Filter..." [canClear]="true" itemsMaxHeightPx="200" itemsWidthPx="400" xPosition="before" yPosition="above"> </lib-filter-select>
 */
@Component({
  selector: "lib-filter-select",
  standalone: true,
  templateUrl: "./filter-select.component.html",
  styleUrls: ["./filter-select.component.scss", "../filter-select-common.scss"],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FilterSelectComponent), multi: true }],
  imports: [
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatMenuModule,
    NgbTooltipModule,
    SimplebarAngularModule,
    TranslocoModule,
    FilterSelectModule
  ]
})
export class FilterSelectComponent<TValue> extends FilterSelectComponentBase<TValue> {
  selectedItem!: IFilterSelectItem<TValue> | any;
  showBtnTitle = false;
  canSave = false;

  protected updateSelectedFromControl(): void {
    const value = this.formControl?.value;
    this.selectedItem = this.items.find((item) => item?.value === value) ?? null;
  }

  override filter() {
    super.filter();

    this.updateSaveBtnVisibility();
  }

  selectSingle(selItem: IFilterSelectItem<TValue>) {
    this.selectedItem = this.items.find((item) => item?.value === selItem.value) ?? null;
    this.updateSaveBtnVisibility();
  }

  resetControl() {
    this.formControl.setValue(null as unknown as TValue);
    this.closeMenu();
  }

  saveControl() {
    this.formControl.setValue(this.selectedItem?.value);
    this.filterText = "";
    this.filter();
  }

  private updateSaveBtnVisibility(): void {
    if (this.filteredItems?.length) {
      this.canSave = !!this.filteredItems.find((x) => x?.value === this.selectedItem?.value);
    }
  }
}
