import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, Renderer2, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "lib-textarea",
  templateUrl: "./textarea.component.html",
  styleUrls: ["./textarea.component.scss"],
  changeDetection: ChangeDetectionStrategy.Default // fixme
})
export class TextareaComponent implements AfterViewInit {
  @Input() control: FormControl | null = null;
  @Input() classList = "form-group";
  @Input() errorClassList = "d-flex";
  @Input() isRequired = false;
  @Input() labelClassList = "form-group";
  @Input() label = "";
  @Input() placeholder = "";
  @Input() rows = 1;

  @ViewChild("textarea") textarea: ElementRef | null = null;

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const rowHeight = 21; // bootstrap row height in px (.form-control class in _form-control.scss)
    const rowVerticalPadding = 7.52 * 2; // bootstrap row vertical padding in px (.form-control class in _form-control.scss)
    const height = this.rows * rowHeight + rowVerticalPadding;

    if (this.textarea?.nativeElement) {
      this.renderer.setStyle(this.textarea.nativeElement, "height", `${height}px`);
    }
  }
}
