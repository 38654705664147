import { CommonModule } from "@angular/common";
import { Component, TemplateRef } from "@angular/core";
import { NgbToastModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslocoModule } from "@ngneat/transloco";
import { Toast } from "./toast";
import { ToastService } from "./toast.service";

@Component({
  selector: "lib-toast",
  templateUrl: "./toast.component.html",
  styleUrls: ["./toast.component.scss"],
  host: { "[class.ngb-toasts]": "true" },
  standalone: true,
  imports: [CommonModule, NgbToastModule, TranslocoModule]
})
export class ToastComponent {
  readonly list$ = this.toastService.toastList$;
  readonly delay = this.toastService.delay;

  constructor(private toastService: ToastService) {}

  isTemplate(toast: Toast): boolean {
    return toast.content instanceof TemplateRef;
  }

  asTemplate(toast: Toast): TemplateRef<any> {
    return toast.content as TemplateRef<any>;
  }

  onRemove(toast: Toast): void {
    this.toastService.remove(toast);
  }
}
