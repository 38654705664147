import { CommonModule } from "@angular/common";
import { InjectionToken, ModuleWithProviders, NgModule } from "@angular/core";
import { ClearlineApiConfig } from "./core";

export const CLEARLINE_API_CONFIG_TOKEN = new InjectionToken<string>("clearline-api_config_token");

@NgModule({
  imports: [CommonModule]
})
export class ClearlineApiModule {
  public static forRoot(config: ClearlineApiConfig): ModuleWithProviders<ClearlineApiModule> {
    return {
      ngModule: ClearlineApiModule,
      providers: [
        {
          provide: CLEARLINE_API_CONFIG_TOKEN,
          useValue: config
        }
      ]
    };
  }
}
