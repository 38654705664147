import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
@Pipe({
  name: "highlight"
})
export class HighlightPipe implements PipeTransform {
  transform(text: string, search: string): string {
    if (search && text && typeof search === "string") {
      let pattern = search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
      pattern = pattern
        .split(" ")
        .filter((t) => {
          return t.length > 0;
        })
        .join("|");
      const regex = new RegExp(pattern, "gi");
      return text.toString().replace(regex, (match) => `<mark>${match}</mark>`);
    } else if (search && search === text?.toString()) {
      return `<mark>${text}</mark>`;
    } else {
      return text;
    }
  }
}
