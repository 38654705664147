import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "lib-form-label",
  templateUrl: "./form-label.component.html",
  styleUrls: ["./form-label.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormLabelComponent {
  @Input() label = "";
  @Input() isRequired = false;
}
