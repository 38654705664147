<div class="custom">
  <div class="modal-header with-subtitle">
    <h5 class="modal-title">
      {{ title }}
    </h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="onCancel()"></button>
  </div>

  <div *ngIf="!!description" class="modal-body pt-3 pb-2">
    <p>{{ description }}</p>
  </div>

  <div class="modal-footer">
    <div class="d-flex {{ !!cancelBtnLabel ? 'justify-content-between w-100' : '' }}">
      <button *ngIf="!!cancelBtnLabel" type="button" class="btn btn-outline w-100 me-3" (click)="onCancel()">
        {{ cancelBtnLabel }}
      </button>

      <button type="button" class="btn btn-primary w-100 ms-3" (click)="onConfirm()">
        {{ confirmBtnLabel }}
      </button>
    </div>
  </div>
</div>
