<button *ngIf="buttonText" [disabled]="disabled" class="btn btn-primary qr-code-btn" (click)="openModal()" [ngbTooltip]="title">
  {{ title }}
</button>

<img
  *ngIf="!buttonText"
  alt=""
  src="assets/images/scan-icon.svg"
  width="18"
  height="18"
  (click)="openModal()"
  [ngbTooltip]="title"
  class="{{ buttonIcon }} align-middle cursor-pointer"
/>
