import { AbstractControl, ValidationErrors } from "@angular/forms";
import { emailRegex } from ".";

export function isValidEmail(value: string) {
  const isValid = emailRegex.test(value);
  return isValid;
}

export function emailValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    const value = control.value.toString() as string;

    const isValid = isValidEmail(value);
    return isValid ? null : { email: { value: control.value, type: "email" } };
  };
}

export function emailListValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string[];
    let isValid = true;
    value.forEach((e) => {
      if (isValid) isValid = isValidEmail(e);
    });

    return isValid ? null : { isValid: { value: control.value }, type: "email" };
  };
}

export function emailUniqueValidator(values: string[]) {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.toString() as string;

    return values.indexOf(value) === -1 ? null : { unique: { value: control.value }, type: "email" };
  };
}

export function emailListUniqueValidator(values: string[]) {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value as string[];
    const counts: any = {};
    [...value, ...values].forEach((x) => {
      counts[x] = (counts[x] || 0) + 1;
    });
    let result = false;
    Object.keys(counts).forEach((c) => {
      if (counts[c] > 1) {
        result = true;
        return;
      }
    });
    return !result ? null : { unique: { value: control.value }, type: "email" };
  };
}

export function isValidPassword(value: string) {
  const regex: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&\/\\\.\,_])[A-Za-z\d@#$!%*?&\/\\\.\,_]{8,20}$/;
  const isValid = regex.test(value);
  return isValid;
}
export function passwordValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.toString() || "";

    const isValid = isValidPassword(value);
    return isValid ? null : { password: { value: control.value, type: "password" } };
  };
}
