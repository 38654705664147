<ngb-toast
  *ngFor="let toast of list$ | async"
  [class]="toast.className"
  [autohide]="true"
  [delay]="toast.delay || delay"
  (hidden)="onRemove(toast)"
>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="asTemplate(toast)"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.content }}</ng-template>
</ngb-toast>
