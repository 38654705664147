<div *transloco="let tc; read: 'common'">
  <button class="select-button w-100" *ngIf="showBtnTitle">
    <div *ngIf="!formControl.value">{{ label }}</div>
    <div *ngIf="formControl.value" [ngbTooltip]="selectedItem?.title" class="selected-value">
      {{ selectedItem?.title }}
    </div>
  </button>

  <div class="filter-select-menu-container p-2">
    <div (click)="$event.stopPropagation()" class="menu-content">
      <div class="input-group d-flex mb-3">
        <input
          [(ngModel)]="filterText"
          (ngModelChange)="filter()"
          (keydown)="filterInputKeydown($event)"
          [placeholder]="tc('controls.search')"
          class="form-control search-input"
        />
        <span class="input-group-text"><i class="ri-search-line"></i></span>
      </div>

      <ngx-simplebar [style.max-height]="itemsMaxHeightPx + 'px'" [style.width]="itemsWidthPx + 'px'" class="mt-1">
        <div
          *ngFor="let item of filteredItems"
          (click)="selectSingle(item)"
          class="mat-menu-item p-2"
          [class.menu-item-selected]="item?.value === selectedItem?.value"
        >
          <div class="menu-item-text">
            <div class="menu-item-title" [innerHTML]="item.title | highlight : filterText"></div>
            <div class="menu-item-description ps-2">{{ item?.description }}</div>
          </div>
        </div>
      </ngx-simplebar>

      <div class="action-area splitter mt-1">
        <button *ngIf="canClear" (click)="resetControl()" type="button" class="btn btn-outline-light btn-card-action mt-2">
          {{ tc("controls.reset") }}
        </button>

        <button
          class="btn btn-primary btn-card-action float-end mt-2 p-1"
          type="button"
          [disabled]="!canSave"
          (click)="saveControl()"
        >
          <span class="dropdown-item btn-save">{{ tc("controls.save") }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
