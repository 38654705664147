import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommonModule } from "@angular/common";
import { TranslocoModule } from "@ngneat/transloco";

@Component({
  selector: "app-confirmation-modal",
  standalone: true,
  templateUrl: "./confirmation-modal.component.html",
  styleUrls: ["./confirmation-modal.component.scss"],
  imports: [CommonModule, TranslocoModule]
})
export class ConfirmationModalComponent {
  constructor(public activeModal: NgbActiveModal) {}

  public title = "";
  public confirmBtnText = "common.controls.confirm";

  confirm() {
    this.activeModal.close(true);
  }

  cancel() {
    this.activeModal.dismiss();
  }
}
