import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormComponentsModule } from "./form-components";
import { QrCodeReaderModule } from "./qr-code-reader";

@NgModule({
  imports: [CommonModule],
  providers: [],
  exports: [FormComponentsModule, QrCodeReaderModule]
})
export class UiKitModule {}
