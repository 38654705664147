<div class="custom">
  <div class="modal-body">
    <button type="button" class="btn-close" data-bs-dismiss="modal" (click)="cancel()"></button>
    <div class="font-size-16">{{ title | transloco }}</div>
  </div>
  <div *transloco="let tc; read: 'common'" class="modal-footer two-actions">
    <button type="button" class="btn btn-outline" (click)="cancel()">{{ tc("controls.cancel") }}</button>
    <button type="button" class="btn btn-main" (click)="confirm()">{{ confirmBtnText | transloco }}</button>
  </div>
</div>
