<div class="{{ classList }}" *ngIf="control">
  <label *ngIf="label" class="col-form-label {{ labelClassList }}">
    {{ label }}<span *ngIf="isRequired" class="text-danger"> *</span>
    </label>
  <div class="position-relative">
    <textarea
      #textarea
      class="form-control"
      [formControl]="control"
      [rows]="rows"
      [placeholder]="placeholder"
    ></textarea>
    <app-form-field-error
      *ngIf="control.touched && !control.valid"
      [label]="label"
      [placeholder]="placeholder"
      [errors]="control.errors"
      class="{{ errorClassList }}"
    ></app-form-field-error>
  </div>
</div>
