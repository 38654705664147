<ng-container *transloco="let t; read: i18nScope">
  <div class="modal-header">
    <h5 class="modal-title">{{ t('qrcode.read') }}</h5>

    <button type="button" class="btn-close" data-bs-dismiss="modal" [title]="t('common.controls.close')" (click)="close()"></button>
  </div>

  <div class="modal-body text-center" [ngClass]="{ 'with-footer': showScan }">
    <div class="container-sm">
      <div class="scan-content" [class.d-none]="!showScanContainer">
        <div class="row">
          <div>
            <div class="input-group mb-3">
              <div (click)="switchCamera()" class="input-group-text">
                <i class="fas fa-sync-alt"></i>
              </div>

              <div id="camera-device-id" type="text" class="form-control modal-text m-0" aria-describedby="switch-camera">
                {{ _deviceName }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <video #cameraStream id="camera-stream" autoplay="true"></video>
        </div>
      </div>

      <div class="scan-error">
        <div>
          <span class="modal-text">
            {{ error }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showScan" class="modal-footer one-action">
    <button *ngIf="showScan" (click)="startScanning()" class="btn btn-main">
      {{ t('qrcode.startScan') }}
    </button>
  </div>
</ng-container>
