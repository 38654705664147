import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "../core";
import {
  CustomerDistributionAccountGetDto,
  CustomerDistributionAccountPostDto,
  CustomerDistributionAccountsPostDto,
  CustomerDistributionConfigurationDto,
  CustomerDistributionDetailsGetDto,
  CustomerDistributionGetDto,
  CustomerDistributionPostDto,
  CustomerDistributionPutDto,
  CustomerDistributionSentMessageResponseDto,
  CustomerDistributionSentResultDto,
  DistributionAccountResultDto
} from "../dto";

@Injectable({
  providedIn: "root"
})
export class CustomerDistributionService {
  private serviceUrl = "CustomerDistribution";

  constructor(private api: ApiService) {}

  getCustomerDistributionAccountList(): Observable<CustomerDistributionDetailsGetDto[]> {
    return this.api.get(`${this.serviceUrl}/account`);
  }

  getCustomerDistributionPartnerList(): Observable<CustomerDistributionDetailsGetDto[]> {
    return this.api.get(`${this.serviceUrl}/partner`);
  }

  getCustomerDistribution(id: number): Observable<CustomerDistributionGetDto> {
    return this.api.get(`${this.serviceUrl}/${id}`);
  }

  getCustomerDistributionCustomers(id: number): Observable<CustomerDistributionSentResultDto[]> {
    return this.api.post(`${this.serviceUrl}/${id}/customers`);
  }

  exportToCsvCustomerDistributionCustomers(id: number): Observable<string> {
    return this.api.post(`${this.serviceUrl}/${id}/customers/export/csv`);
  }

  updateCustomerDistribution(id: number, data: CustomerDistributionPutDto): Observable<CustomerDistributionGetDto> {
    return this.api.put(`${this.serviceUrl}/${id}`, data);
  }

  delete(id: number): Observable<void> {
    return this.api.delete(`${this.serviceUrl}/${id}`);
  }

  createCustomerDistribution(data: CustomerDistributionPostDto): Observable<CustomerDistributionGetDto> {
    return this.api.post(`${this.serviceUrl}`, data);
  }

  getCustomerDistributionAccounts(distributionId: number): Observable<DistributionAccountResultDto[]> {
    return this.api.get(`${this.serviceUrl}/${distributionId}/accounts`);
  }

  createCustomerDistributionAccounts(
    distributionId: number,
    data: CustomerDistributionAccountsPostDto
  ): Observable<CustomerDistributionAccountGetDto> {
    return this.api.post(`${this.serviceUrl}/${distributionId}/accounts`, data);
  }

  updateCustomerDistributionAccounts(
    distributionId: number,
    data: CustomerDistributionAccountsPostDto
  ): Observable<CustomerDistributionAccountPostDto[]> {
    return this.api.put(`${this.serviceUrl}/${distributionId}/accounts`, data);
  }

  sendCustomerDistribution(distributionId: number): Observable<CustomerDistributionSentMessageResponseDto> {
    return this.api.post(`${this.serviceUrl}/${distributionId}/send`);
  }

  getStatusCustomerDistribution(distributionId: number): Observable<CustomerDistributionConfigurationDto> {
    return this.api.post(`${this.serviceUrl}/${distributionId}/send/status`);
  }

  getCustomersCount(data: CustomerDistributionAccountsPostDto): Observable<number> {
    return this.api
      .post<number | { data: number }>(`${this.serviceUrl}/customers/count`, data)
      .pipe(map((v) => (typeof v === "number" ? v : v.data)));
  }
}
