import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";

type Option<T> = { label: string; value: T };

@Component({
  selector: "lib-expandable-button",
  templateUrl: "./expandable-button.component.html",
  styleUrls: ["./expandable-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgbDropdownModule]
})
export class ExpandableButtonComponent<T> {
  @Input() disabled = false;
  @Input() title = "";
  @Input() options: Option<T>[] = [];

  @Output() selected = new EventEmitter<T>();

  onSelect(value: T): void {
    this.selected.emit(value);
  }
}
