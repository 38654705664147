import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

export function ngbDateStructToDate(ngbDate: NgbDateStruct, hh = 0, mm = 0, ss = 0, ms = 0): Date {
  return new Date(Date.UTC(ngbDate.year, ngbDate.month - 1, ngbDate.day, hh, mm, ss, ms));
}

export function dateToNgbDateStruct(date: Date): NgbDateStruct {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate()
  };
}
