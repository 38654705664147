<mat-stepper
  #stepper
  labelPosition="bottom"
  [@.disabled]="true"
  [linear]="true"
  [disableRipple]="true"
  (selectionChange)="selectionChange($event)">

  <ng-container *ngFor="let item of steps; let i = index">
  <mat-step
    *ngIf="item.stepControl && !item.hidden"
    [label]="item.label"
    [stepControl]="item.stepControl">

    <ng-template matStepContent>
      <div class="card">
        <div
          [class.card-content-modal]="options.isModalWindow"
          [class.px-0]="options.isModalWindow"
          [class.card-content-center]="options.justifyContentCenter"
          [class.card-content-no-footer]="options.hiddenFooterButtons"
          class="card-body card-content position-relative pt-2"
          id="card-body">
          <!-- STEP template content -->
          <ng-container *ngTemplateOutlet="templates[i]"></ng-container>

          <!-- Footer buttons -->
          <ng-container *ngIf="!options.hiddenFooterButtons">
            <ul
              *transloco="let t; read: 'common.controls'"
              class="list-inline wizard card-bottom position-absolute bottom-0 start-0">
              <li *ngIf="!(options.button?.next?.isWide && i < steps.length - 1) || (options.button?.complete?.isWide && i === steps.length - 1)" class="previous list-inline-item">
                <button
                  *ngIf="i === 0"
                  matStepperPrevious
                  class="btn btn-outline-light btn-step"
                  (click)="cancel.emit()">
                  {{ options.button?.cancel?.label ?  options.button?.cancel?.label : t("cancel") }}
                </button>

                <button
                  *ngIf="i > 0"
                  matStepperPrevious
                  class="btn btn-outline-light btn-step">
                  {{ options.button?.previous?.label ?  options.button?.previous?.label : t("previous") }}
                </button>
              </li>

              <li
                class="next list-inline-item float-end"
                [class.wide-button]="(options.button?.next?.isWide && i < steps.length - 1) || (options.button?.complete?.isWide && i === steps.length - 1)">
                <button
                  *ngIf="i < steps.length - 1"
                  matStepperNext
                  type="button"
                  class="btn btn-primary btn-step"
                  [disabled]="item.stepControl.invalid"
                  [class.wide-button]="options.button?.next?.isWide">
                  {{ options.button?.next?.label ?  options.button?.next?.label : t("next") }}
                </button>

                <button
                  *ngIf="i === steps.length - 1"
                  matStepperNext
                  type="button"
                  class="btn btn-primary btn-step"
                  [disabled]="item.stepControl.invalid"
                  [class.wide-button]="options.button?.complete?.isWide"
                  (click)="confirm.emit()">
                  {{ options.button?.complete?.label ?  options.button?.complete?.label : t("complete") }}
                </button>
              </li>
            </ul>
          </ng-container>
        </div>
      </div>
    </ng-template>
  </mat-step>
</ng-container>

  <ng-template matStepperIcon="edit" let-index="index">
    {{ index + 1 }}
  </ng-template>
  <ng-template matStepperIcon="done">
    <i class="ri-check-line"></i>
  </ng-template>
</mat-stepper>
