export enum CustomerDistributionStatus {
  New = "New",
  Draft = "Draft",
  InProgress = "InProgress",
  Sent = "Sent",
  Error = "Error",
  Complete = "Complete"
}

export enum DeliveryChannels {
  Undefined = "Undefined",
  Email = "Email",
  Sms = "Sms",
  EmailAndSms = "EmailAndSms",
  Qr = "Qr",
  HyperLink = "HyperLink",
  Mms = "Mms",
  All = "All"
}
