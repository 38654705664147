import { HttpErrorResponse } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, OperatorFunction, of, throwError } from "rxjs";
import { catchError, delay, mergeMap, retryWhen } from "rxjs/operators";

/** function to receive error message from error. */
export function httpErrorMessageHandler(err: HttpErrorResponse): string {
  if (err.status == 403) return "Forbidden";
  return err.error.Message ?? err.error;
}
/** Pipe for Clearline API requests. Alerts API error message.
 * @param errorMessageFn - function to receive error message from error.
 */
export function alertApiError<T>(
  toasterService: { danger: (content: any) => void },
  errorMessageFn: (x: HttpErrorResponse) => string = httpErrorMessageHandler
): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(
      catchError((err: HttpErrorResponse) => {
        const errorMsg = errorMessageFn(err);
        toasterService.danger(errorMsg);
        return throwError(err);
      })
    );
  };
}

/** Pipe. Navigates to path on error.
 * @param path - Navigate to path items. Default - parent path.
 */
export function navigateOnError<T>(router: Router, route: ActivatedRoute, paths: any[] = [".."]): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(
      catchError((err) => {
        router.navigate(paths, { relativeTo: route });
        return throwError(err);
      })
    );
  };
}

/** Pipe for Clearline API requests. Alerts API error message. */
export function retryOnError<T>(attemptsCount: number, delayMs: number): OperatorFunction<T, T> {
  return (source: Observable<T>) => {
    return source.pipe(
      retryWhen((errors) =>
        errors.pipe(
          mergeMap((err, attemptInd) => {
            if (attemptInd < attemptsCount - 1) return of(err);
            return throwError(err);
          }),
          delay(delayMs)
        )
      )
    );
  };
}
