export enum RoleTypes {
  Sales = "sales",
  Manager = "manager",
  Admin = "admin",
  LocationManager = "location-manager",
  PartnerAdmin = "partner-admin"
}

export enum RoleId {
  Admin = 1,
  Manager = 2,
  Sales = 3,
  LocationManager = 4,
  PartnerAdmin = 5
}

export interface Role {
  id: number;
  name: string;
}
